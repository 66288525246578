import React, { useState } from 'react';
import './Welcome.css';

const Welcome = ({ email, navigateToConfirmation }) => {
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [subjectError, setSubjectError] = useState('');
  const [messageError, setMessageError] = useState('');
  const [wordCount, setWordCount] = useState(0);
  const [formStatus, setFormStatus] = useState({ status: 'success', message: '' });

  const handleSubmit = async (e) => {
    e.preventDefault();

    let valid = true;
    if (!subject) {
      setSubjectError('Please complete all required fields.');
      valid = false;
    } else {
      setSubjectError('');
    }

    if (!message) {
      setMessageError('Please complete all required fields.');
      valid = false;
    } else if (wordCount < 20) {
      setMessageError('Please write at least 20 words before sending.');
      valid = false;
    } else {
      setMessageError('');
    }

    if (valid) {
      setFormStatus({ status: 'fetching' });

      try {
        const base_url = process.env.REACT_APP_DRUPAL_BASE_URL;
        if (!base_url) {
          setFormStatus({ status: 'error', message: 'Base URL is not set.' });
          return;
        }

        const response = await fetch(`${base_url}/webform_rest/submit`, {
          method: 'POST',
          body: JSON.stringify({
            webform_id: 'contact',
            name: 'User',
            email,
            subject,
            message,
          }),
          headers: { 'Content-Type': 'application/json' },
        });

        if (!response.ok) throw new Error('Submission failed');

        setFormStatus({ status: 'success', message: 'Message sent successfully!' });
        console.log('Message sent successfully');
        navigateToConfirmation(); // Navigate to confirmation page if successful
      } catch (error) {
        setFormStatus({ status: 'error', message: 'An error occurred. Try again.' });
        console.error('Error with form submission:', error);
      }
    }
  };

  const handleMessageChange = (e) => {
    const value = e.target.value;
    setMessage(value);
    setWordCount(value.split(' ').filter((word) => word).length);
  };

  return (
    <div className="container">
      <div className="left-section">
        <div className="logo-container">
          <img src="/logo.png" alt="Logo" className="logo" />
        </div>
        <div className="text-container">
          <div className="sign-in-text">Welcome</div>
          <div className="dropdown-container">
            <div className="dropdown-content">
              <img
                src="/profile-icon.png"
                alt="Profile Icon"
                className="profile-icon"
              />
              <span className="email">{email}</span>
              <svg
                aria-hidden="true"
                className="dropdown-arrow"
                fill="currentColor"
                focusable="false"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                xmlns="https://www.w3.org/2000/svg"
              >
                <path d="M7 10l5 5 5-5z"></path>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div className="right-section">
        <form id="contact-form" onSubmit={handleSubmit}>
          <input
            type="hidden"
            name="_subject"
            value="New contact form submission"
          />
          <input type="hidden" name="_replyto" value={email || ''} />
          <div className="input-group">
            <div className={`input-container ${subjectError ? 'error' : ''}`}>
              <input
                type="text"
                name="subject"
                id="subject"
                className="input"
                placeholder=" "
                value={subject || ''}
                onChange={(e) => setSubject(e.target.value)}
                required
              />
              <label htmlFor="subject" className="input-label">
                Subject
              </label>
              {subjectError && (
                <div className="error-message subject-error">
                  <svg
                    aria-hidden="true"
                    className="error-icon"
                    fill="currentColor"
                    focusable="false"
                    width="16px"
                    height="16px"
                    viewBox="0 0 24 24"
                    xmlns="https://www.w3.org/2000/svg"
                  >
                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"></path>
                  </svg>
                  {subjectError}
                </div>
              )}
            </div>
            <div
              className={`input-container message ${
                messageError ? 'error' : ''
              }`}
            >
              <textarea
                name="message"
                id="message"
                className="input"
                placeholder=" "
                value={message || ''}
                onChange={handleMessageChange}
                required
              />
              <label htmlFor="message" className="input-label">
                Message
              </label>
              {messageError && (
                <div className="error-message message-error">
                  <svg
                    aria-hidden="true"
                    className="error-icon"
                    fill="currentColor"
                    focusable="false"
                    width="16px"
                    height="16px"
                    viewBox="0 0 24 24"
                    xmlns="https://www.w3.org/2000/svg"
                  >
                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"></path>
                  </svg>
                  {messageError}
                </div>
              )}
              <div className="word-count">{wordCount} words</div>
            </div>
          </div>
          {formStatus.message && <p>{formStatus.message}</p>}
          <button className="next-button" type="submit" disabled={formStatus.status === 'fetching'}>
            {formStatus.status === 'fetching' ? 'Sending...' : 'Send'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Welcome;
