import React, { useState } from 'react';
import './App.css';
import SignIn from './components/SignIn';
import Welcome from './components/Welcome';
import Confirmation from './components/Confirmation';

function App() {
  const [page, setPage] = useState('signin');
  const [inputValue, setInputValue] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [subjectError, setSubjectError] = useState('');
  const [messageError, setMessageError] = useState('');
  const [wordCount, setWordCount] = useState(0);

  const handleNextClick = (value) => {
    setInputValue(value);
    setPage('welcome');
  };

  const handleSend = () => {
    let valid = true;
    if (!subject) {
      setSubjectError('Please complete all required fields.');
      valid = false;
    } else {
      setSubjectError('');
    }

    if (!message) {
      setMessageError('Please complete all required fields.');
      valid = false;
    } else if (wordCount < 200) {
      setMessageError('Please write 200 words before sending to avoid spam.');
      valid = false;
    } else {
      setMessageError('');
    }

    if (valid) {
      setPage('confirmation');
    }
  };

  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
  };

  const handleMessageChange = (e) => {
    const value = e.target.value;
    setMessage(value);
    setWordCount(value.split(' ').filter(word => word).length);
  };

  return (
    <div className="App">
      {page === 'signin' && <SignIn onNext={handleNextClick} />}
      {page === 'welcome' && (
        <Welcome
          email={inputValue}
          subject={subject}
          message={message}
          subjectError={subjectError}
          messageError={messageError}
          wordCount={wordCount}
          onSubjectChange={handleSubjectChange}
          onMessageChange={handleMessageChange}
          onSend={handleSend}
        />
      )}
      {page === 'confirmation' && <Confirmation />}
    </div>
  );
}

export default App;
