import React, { useState } from 'react';
import './SignIn.css';
import logo from '../logo.png';
import Welcome from './Welcome';

const SignIn = ({ onNext }) => {
    const [inputValue, setInputValue] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [showWelcome, setShowWelcome] = useState(false);

    const handleNextClick = async () => {
        if (!inputValue) {
            setErrorMessage('Enter an email or phone number.');
        } else if (!validateEmailOrPhone(inputValue)) {
            setErrorMessage('Enter a valid email or phone number.');
        } else if (isBogusEmail(inputValue) || isGibberish(inputValue)) {
            setErrorMessage('Couldn\'t find your Jamie Account');
        } else {
            const isValidDomain = await performDnsCheck(inputValue);
            if (isValidDomain) {
                // Make sure grecaptcha is defined before using it
                if (typeof window.grecaptcha !== 'undefined') {
                    const token = await executeRecaptcha();
                    if (token) {
                        setErrorMessage('');
                        setShowWelcome(true);
                    } else {
                        setErrorMessage('reCAPTCHA verification failed.');
                    }
                } else {
                    console.error('reCAPTCHA is not loaded');
                    setErrorMessage('reCAPTCHA is not available.');
                }
            } else {
                setErrorMessage('Couldn’t find your Jamie Account');
            }
        }
    };

    const executeRecaptcha = async () => {
        try {
            const token = await window.grecaptcha.execute('6Lflow8qAAAAAO-Pliun7NpbQIL2XgNYp6Tl9EpQ', { action: 'submit' });
            return token;
        } catch (error) {
            console.error('Error executing reCAPTCHA:', error);
            return null;
        }
    };

    const validateEmailOrPhone = (value) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[a-z]{2,}$/i;
        const phoneRegex = /^[0-9]{7,15}$/;
        return emailRegex.test(value) || phoneRegex.test(value);
    };

    const isBogusEmail = (value) => {
        const bogusEmails = ['spam@bogus.com', 'no-reply@nonexistent.com'];
        const domainRegex = /\.(fake|invalid|bogus)$/;
        return bogusEmails.includes(value.toLowerCase()) || domainRegex.test(value.toLowerCase());
    };

    const isGibberish = (value) => {
        const repeatedCharRegex = /(.)\1{4,}/;
        const vowelConsonantRatio = value.replace(/[^aeiou]/gi, "").length / value.replace(/[aeiou]/gi, "").length;
        const minRatio = 0.2;

        return repeatedCharRegex.test(value) || vowelConsonantRatio < minRatio;
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleNextClick();
        }
    };

    const performDnsCheck = async (email) => {
        const domain = email.split('@')[1];
        if (!domain) return false;

        try {
            const response = await fetch(`https://dns.google/resolve?name=${domain}&type=MX`);
            if (!response.ok) throw new Error('DNS lookup failed');
            const result = await response.json();
            return result.Answer && result.Answer.length > 0;
        } catch (error) {
            console.error('Error during DNS check:', error);
            return false;
        }
    };

    return (
        <>
            {!showWelcome ? (
                <div className="App">
                    <div className="container">
                        <div className="left-section">
                            <div className="logo-container">
                                <img src={logo} alt="Logo" className="logo" />
                            </div>
                            <div className="text-container">
                                <div className="sign-in-text">Contact me</div>
                                <div className="account-text">Use your Jamie Account</div>
                            </div>
                        </div>
                        <div className="right-section">
                            <div className="input-group">
                                <div className={`input-container ${errorMessage ? 'error' : ''}`}>
                                    <input
                                        type="text"
                                        id="email"
                                        className="input"
                                        placeholder=" "
                                        value={inputValue}
                                        onChange={(e) => setInputValue(e.target.value)}
                                        onKeyDown={handleKeyDown}
                                        required
                                    />
                                    <label htmlFor="email" className="input-label">Email or phone</label>
                                    {errorMessage && (
                                        <div className="error-message">
                                            <svg aria-hidden="true" className="error-icon" fill="currentColor" focusable="false" width="16px" height="16px" viewBox="0 0 24 24" xmlns="https://www.w3.org/2000/svg">
                                                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"></path>
                                            </svg>
                                            {errorMessage}
                                        </div>
                                    )}
                                </div>
                                <a href="#" className="forgot-email">Forgot email?</a>
                            </div>
                            <div className="info-text">
                                <p>Not your computer? Use Guest mode to sign in privately.</p>
                                <a href="#" className="learn-more">Learn more about using Guest mode</a>
                            </div>
                            <div className="actions">
                                <button className="create-account">Create account</button>
                                <button className="next-button" onClick={handleNextClick}>Next</button>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <Welcome email={inputValue} onSend={() => console.log('Email Sent')} />
            )}
        </>
    );
};

export default SignIn;
